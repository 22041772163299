import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    static targets = ["title", "body", "quote", "dialog", "quoteAuthor"];

    open(values) {
        const { title, body, quote, quoteAuthor } = values;
        if (title) this.titleTarget.textContent = title;
        if (body) this.bodyTarget.textContent = body;
        if (quote) this.quoteTarget.textContent = quote;
        if (quoteAuthor)
            this.quoteAuthorTarget.textContent = `- ${quoteAuthor}`;
        this.dialogTarget.showModal();
    }

    clearValues() {
        this.titleTarget.textContent = "";
        this.bodyTarget.textContent = "";
        this.quoteTarget.textContent = "";
        this.quoteAuthorTarget.textContent = "";
    }

    close() {
        this.clearValues();
        this.dialogTarget.close();
    }
}
