import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    static targets = ["card"];
    static outlets = ["modal"];
    static values = {
        review: Object,
    };

    showDetails(e) {
        e.stopPropagation();
        const review = this.reviewValue.review;
        const { message, customer } = review;
        this.modalOutlet.open({
            title: "Review",
            quote: message,
            quoteAuthor: customer,
        });
    }
}
